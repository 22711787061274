import * as Types from './types'
import { ContentfulCollection, createClient } from 'contentful'
import { env } from '../init/env'

/**
 * A string that (if set) indicates the _current_ Contentful environment used
 * to preview content from. This comes from the query string parameter
 * `contentful_preview_environment`.
 */
export const previewEnvironment = new URLSearchParams(window.location.search).get('contentful_preview_environment')

export const contentfulClient = createClient({
  host: previewEnvironment ? env.VITE_CONTENTFUL_PREVIEW_HOST : env.VITE_CONTENTFUL_HOST,
  accessToken: previewEnvironment ? env.VITE_CONTENTFUL_PREVIEW_TOKEN : env.VITE_CONTENTFUL_TOKEN,
  environment: previewEnvironment ? previewEnvironment : env.VITE_CONTENTFUL_ENVIRONMENT,
  space: env.VITE_CONTENTFUL_SPACE,
})

/* ========================================================================== */

export function getTypedEntries(type: 'box', query?: Record<string, any>): Promise<ContentfulCollection<Types.IBox>>
export function getTypedEntries(type: 'boxList', query?: Record<string, any>): Promise<ContentfulCollection<Types.IBoxList>>
export function getTypedEntries(type: 'dataContent', query?: Record<string, any>): Promise<ContentfulCollection<Types.IDataContent>>
export function getTypedEntries(type: 'dish', query?: Record<string, any>): Promise<ContentfulCollection<Types.IDish>>
export function getTypedEntries(type: 'dishCarousel', query?: Record<string, any>): Promise<ContentfulCollection<Types.IDishCarousel>>
export function getTypedEntries(type: 'dishList', query?: Record<string, any>): Promise<ContentfulCollection<Types.IDishList>>
export function getTypedEntries(type: 'dishShowcase', query?: Record<string, any>): Promise<ContentfulCollection<Types.IDishShowcase>>
export function getTypedEntries(type: 'iconText', query?: Record<string, any>): Promise<ContentfulCollection<Types.IIconText>>
export function getTypedEntries(type: 'iconTextList', query?: Record<string, any>): Promise<ContentfulCollection<Types.IIconTextList>>
export function getTypedEntries(type: 'list', query?: Record<string, any>): Promise<ContentfulCollection<Types.IList>>
export function getTypedEntries(type: 'page', query?: Record<string, any>): Promise<ContentfulCollection<Types.IPage>>
export function getTypedEntries(type: 'panel', query?: Record<string, any>): Promise<ContentfulCollection<Types.IPanel>>
export function getTypedEntries(type: 'prefilledCart', query?: Record<string, any>): Promise<ContentfulCollection<Types.IPrefilledCart>>
export function getTypedEntries(type: 'qa', query?: Record<string, any>): Promise<ContentfulCollection<Types.IQa>>
export function getTypedEntries(type: 'qaList', query?: Record<string, any>): Promise<ContentfulCollection<Types.IQaList>>
export function getTypedEntries(type: 'testimonial', query?: Record<string, any>): Promise<ContentfulCollection<Types.ITestimonial>>
export function getTypedEntries(type: 'testimonialList', query?: Record<string, any>): Promise<ContentfulCollection<Types.ITestimonialList>>
export function getTypedEntries(type: 'translations', query?: Record<string, any>): Promise<ContentfulCollection<Types.ITranslations>>
export function getTypedEntries(type: 'translationsRichText', query?: Record<string, any>): Promise<ContentfulCollection<Types.ITranslationsRichText>>
export function getTypedEntries(type: 'userRatings', query?: Record<string, any>): Promise<ContentfulCollection<Types.IUserRatings>>

// Overloaded "getEntries" method
export function getTypedEntries(type: Types.CONTENT_TYPE, query?: Record<string, any>): Promise<ContentfulCollection<any>> {
  return contentfulClient.getEntries(Object.assign({}, query, { content_type: type }))
}
