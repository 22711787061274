<!--==================================================================+
| DISH TAGS FILTER                                                    |
+===================================================================-->
<template>
  <div class="filters" :class="[{ open: filter_open, need_toggle }, { with_nutri_pref: nutri_pref }]">
    <div class="toggle" @click="toggleFilter()">
      {{ filter_open ? $t('tags.less') : $t('tags.more') }}
      <arrow class="arrow" />
    </div>
    <div
      v-if="showFilter || orderflow"
      class="content filter-wrapper"
      :class="{ invisible: !dishes.length }"
    >
      <!--==================================================================+
      | 'ALL', 'FAV', 'NEW', 'BACK' ARE FIXED TAGS ON DESKTOP               |
      +===================================================================-->
      <juit-badge
        class="small all desktop"
        :class="{ revert: !filter }"
        @click="filter = ''"
      >
        <span>{{ $t("dishes.all_dishes") }}</span>
      </juit-badge>
      <juit-badge
        v-if="favTag in tags"
        class="small desktop"
        :class="{ revert: filter === favTag }"
        @click="filterChange(favTag)"
      >
        <span>{{ $t("tags.favorite") }}</span>
        <juit-tag-icon :cat="'favorite'" />
      </juit-badge>
      <juit-badge
        v-if="'new.yellow.new' in tags"
        class="small yellow desktop"
        :class="{ revert: filter === 'new.yellow.new' }"
        @click="filterChange('new.yellow.new')"
      >
        <span>{{ $t("tags.new") }}</span>
        <juit-tag-icon :cat="'new'" />
      </juit-badge>
      <juit-badge
        v-if="'back.yellow.back' in tags"
        class="small yellow desktop"
        :class="{ revert: filter === 'back.yellow.back' }"
        @click="filterChange('back.yellow.back')"
      >
        <span>{{ $t("tags.back") }}</span>
        <juit-tag-icon :cat="'back'" />
      </juit-badge>

      <!--==================================================================+
      | TAG CAROUSEL                                                        |
      +===================================================================-->
      <juit-carousel
        ref="csroller"
        :scroll-multiples="0"
        class="desktop-only-arrow small-arrow gradient-arrow"
        @arrow="toggleFilter($event)"
      >
        <div class="tile all">
          <juit-badge
            class="small all"
            :class="{ revert: !filter }"
            @click="filter = ''"
          >
            <span>{{ $t("dishes.all_dishes") }}</span>
          </juit-badge>
        </div>
        <div
          v-for="(detail, tag) in tags"
          :key="tag"
          :class="[detail[0], detail[2], 'tile']"
          @click="filterChange(tag)"
        >
          <juit-badge :class="[{ 'revert': filter === tag }, detail[0], detail[1], detail[2], 'small']">
            <span>{{ $t(`tags.${detail[2]}`) }}</span>
            <juit-tag-icon :cat="detail[0]" :name="detail[2]" />
          </juit-badge>
        </div>
      </juit-carousel>
    </div>

    <!--==================================================================+
    | SECONDARY FILTER                                                    |
    +===================================================================-->
    <div v-if="advancedFilters && Object.keys(tags_secondary).length" class="tags-secondary">
      <span class="font-bold mr-2 self-center uppercase mobile:w-full mobile:mb-1">{{ $t('tags.secondary') }}</span>
      <label
        v-for="(detail, tag) in tags_secondary"
        :key="tag"
        class="checkbox-label"
        :class="detail[2]"
      >
        <input
          v-model="filters_without"
          :value="tag"
          type="checkbox"
          @click="filterChange(tag)"
        >
        <div class="checkbox-checked" />
        <p>{{ $t(`tags.${detail[2]}`) }}</p>
      </label>
      <p class="secondary-filter-text">
        {{ $t('tags.secondary-legal') }}
      </p>
    </div>

    <!--==================================================================+
    | SORTING                                                             |
    +===================================================================-->
    <div v-if="advancedFilters" class="tags-sorting">
      <span class="sort-text">{{ $t('tags.sorting') }}</span>
      <juit-carousel :scroll-multiples="0" class="small-arrow gradient-arrow">
        <div
          v-for="(single_option, index) in sorting_options"
          :key="index"
          class="tile"
          :class="{ hidden: single_option === 'recommended' && !user_recommended_list }"
        >
          <juit-badge
            v-if="single_option !== 'recommended' || user_recommended_list"
            class="round tiny !px-2 mobile:py-0.5"
            :class="{ revert: sorting_key === single_option }"
            @click="sortingChange(single_option)"
          >
            <span>{{ $t(`tags.sortby-${single_option}`) }}</span>
          </juit-badge>
        </div>
      </juit-carousel>
    </div>
  </div>
  <juit-no-result v-if="!products_filtered_sorted.length" :isfav="filter === favTag && !filters_without.length" class="mt-4" />
</template>

<script lang="ts">
  import juitBadge from '../widgets/juit-badge.vue'
  import juitNoResult from '../widgets/juit-no-result.vue'
  import juitCarousel from '../widgets/juit-carousel.vue'
  import juitTagIcon, { splitTags, Tags, favTag } from '../widgets/juit-tag-icon'
  import { Dish, fetchAllProducts } from '../content'
  import { analyticsEvent } from '../analytics'
  import { arrow } from '../assets/async'
  import { defineComponent, PropType } from 'vue'
  import { locale } from '../init/i18n'
  import { log, logError } from '../init/log'
  import { dishRatings, dishRatingsMine, favDishes, nutriPref } from '../init/state'
  import { checkoutQueue } from '../init/checkout-queue'
  import { available_eans, initializeInventories } from '../init/inventories'
  import { user } from '../init/client'
  import { preferences } from '../components/dish-preferences.vue'

  export type DishSortingKey = 'recommended' | 'carb' | 'calories' | 'protein' | 'fat'
  export default defineComponent({
    components: { juitBadge, juitTagIcon, juitCarousel, juitNoResult, arrow },

    props: {
      orderflow: {
        type: Boolean,
        required: false,
        default: false,
      },
      listOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      availableOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      showFilter: {
        type: Boolean,
        required: false,
        default: true,
      },
      list: {
        type: Object as PropType<Dish[]>,
        required: true,
      },
      allDishes: {
        type: Object as PropType<Dish[]>,
        required: false,
        default: undefined,
      },
      advancedFilters: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    emits: [ 'filtered' ],

    data: () => ({
      dishes: [] as Dish[],
      dishes_backup: [] as Dish[],
      filter: '',
      filter_open: false,
      filters_without: [] as string[],
      sorting_options: [ 'recommended', '', 'protein', 'carb', 'calories' ] as DishSortingKey[],
      sorting_key: '' as DishSortingKey,
      need_toggle: false,
      favTag,
      favBusy: false,
    }),

    computed: {
      load_preference_check() {
        return checkoutQueue.loading_user_preference
      },
      fav_dishes() {
        return favDishes.value
      },
      available_eans() {
        return available_eans.value
      },
      nutri_pref() {
        return nutriPref.value
      },

      /* ========================================================================== *
       * Return a group of { tag: [cat, color, name] } for all tags of all producs  *
       * -------------------------------------------------------------------------- */
      tags(): Tags {
        // Filter all product tags to uniquely extract `diet...` tags
        const tagSet = new Set<string>()
        this.dishes.forEach((product) => (product.tags.forEach((tag) => tagSet.add(tag))))
        if (this.user && !tagSet.has(favTag)) tagSet.add(favTag)
        const tags = [ ...tagSet ].sort()

        // Reduce list of tags to groups of { tag: [cat, color, name] }
        return splitTags(tags)
      },

      tags_secondary(): Tags {
        const tags = {} as Tags
        for (const key in this.tags) if (this.tags[key][0] === 'secondary') tags[key] = this.tags[key]
        return tags
      },

      /* ========================================================================== *
       * Return the list of products, possibly filtered by tag and sorted           *
       * -------------------------------------------------------------------------- */
      products_filtered_sorted(): Dish[] {
        const tags = [ this.filter, ...this.filters_without ]
        // Show only available dishes?
        const visible = [] as Dish[]
        this.dishes.forEach((dish) => {
          if (this.available_eans.includes(dish.ean)) visible.push(dish)
          else if (dish.alwaysDisplay && dish.sellable) visible.push({ ...dish, soldout: true })
        })
        const dishes = this.availableOnly ? visible : this.dishes
        // Make sure we only show dishes with all selected tags
        const filtered = dishes.filter((product) => {
          // Always include '' (show all)
          return tags.every((tag) => [ '', ...product.tags ].includes(tag))
        })
        // Sort dishes
        if (!this.sorting_key || this.sorting_key === 'recommended') return filtered
        return filtered.sort((a, b) => {
          const result_ascend = [ 'carb', 'calories', 'protein' ].includes(this.sorting_key) ?
            ((a[this.sorting_key] || 0) * a.weight / 100) - ((b[this.sorting_key] || 0) * b.weight / 100) :
            (a[this.sorting_key] || 0) - (b[this.sorting_key] || 0)
          return [ 'carb', 'calories' ].includes(this.sorting_key) ? result_ascend : 0 - result_ascend
        })
      },

      /* ========================================================================== *
       * Return the list of recommended dishes for current user                     *
       * -------------------------------------------------------------------------- */
      user_recommended_list() {
        if (!user.value || !user.value.preferences.recommended) return undefined
        else return user.value.preferences.recommended.products?.map((fav_dish: string) => this.dishes.find((dish) => dish.ean === fav_dish)).filter((item) => item)
      },

      /* ========================================================================== *
       * Carousel roller                                                            *
       * -------------------------------------------------------------------------- */
      tagroller(): HTMLElement {
        return this.$refs.tagroller as HTMLElement
      },

      my_ratings() {
        return dishRatingsMine.value
      },

    },

    watch: {
      tags: {
        handler(tags) {
          if (tags) {
            this.checkToggle()
            this.resolveURLParams()
          }
        },
        immediate: true,
        deep: true,
      },

      products_filtered_sorted() {
        this.$emit('filtered', this.products_filtered_sorted)
      },

      fav_dishes: {
        handler() {
          this.$nextTick(() => this.updateFavs())
        },
        deep: true,
      },

      // GA event for normal tags
      filter(filter: string): void {
        const tag = (filter ? this.tags[filter][2] : 'all') || 'unknown'
        analyticsEvent('select_filter', { tag_name: tag })
      },

      // For user recommended dish list
      sorting_key(key) {
        const list = key === 'recommended' ? this.user_recommended_list as Dish[] : this.list
        this.addRestDishes(list, this.dishes)
      },
      load_preference_check: {
        handler(value) {
          if (!this.advancedFilters) return
          this.$nextTick(() => {
            if (this.nutri_pref && this.nutri_pref !== 'default') this.sorting_key = this.nutri_pref
            else this.sorting_key = (value !== 'inactive' && this.user_recommended_list) ? 'recommended' : '' as DishSortingKey
          })
        },
        immediate: true,
      },

      list: {
        handler() {
          if (this.orderflow && this.allDishes) {
            this.addRestDishes(this.user_recommended_list?.length ? this.user_recommended_list as Dish[] : this.list, this.allDishes)
            this.setDishBackups()

          /* ========================================================================== *
           * Show the rest of the dish in random order after the given list             *
           * -------------------------------------------------------------------------- */
          } else if (!this.listOnly) {
            log('%cFetching unlisted dishes...', 'color: firebrick')
            fetchAllProducts(locale.value).then(async (dishes) => {
              const list = this.sorting_key === 'recommended' ? this.user_recommended_list as Dish[] : this.list
              if (dishes) this.addRestDishes(list, dishes)
              this.setDishBackups()
            }).catch((error) => logError(`Error fetching products (locale="${locale}")`, error))
          } else {
            this.dishes = this.list
            this.$emit('filtered', this.dishes)
            this.setDishBackups()
          }
        },
        immediate: true,
      },
    },

    async mounted() {
      window.addEventListener('resize', this.checkToggle)
      this.updateFavs()
      const stored_pref = localStorage.getItem('jn:nutri_pref') as DishSortingKey | '' | 'default'
      if (stored_pref && preferences.includes(stored_pref)) nutriPref.value = stored_pref
      if (this.availableOnly && (checkoutQueue.init_inventory === 'inactive')) await initializeInventories()
    },

    unmounted() {
      window.removeEventListener('resize', this.checkToggle)
    },

    methods: {
      checkToggle() {
        const component = this.$refs.csroller as InstanceType<typeof juitCarousel>
        if (!component) return
        this.filter_open = false
        setTimeout(() => { // Give the DOMs some time
          this.need_toggle = component.wrapper?.getBoundingClientRect().height < component.roller?.getBoundingClientRect().height
        }, 200)
      },

      resolveURLParams() {
        const dish_tag = (new URLSearchParams(globalThis.window.location.search)).get('dishtag') || ''
        const dish_without_tags = ((new URLSearchParams(globalThis.window.location.search)).get('dishwithout') || '').split(' ')
        if (dish_tag) this.filter = Object.keys(this.tags).find((tag) => tag.split('.')[2] === dish_tag) || ''
        if (dish_without_tags.length) {
          dish_without_tags.forEach((without_tag) => {
            const match = (Object.keys(this.tags_secondary).find((tag) => tag.split('.')[2] === without_tag))
            if (match) this.filters_without.push(match)
          })
        }
      },

      setDishBackups() {
        this.updateFavs()
        this.dishes.forEach((dish) => this.addRatingScores(dish))
        this.dishes_backup = this.dishes
      },

      /* ========================================================================== *
       * Update fav dishes and add / remove tag                                     *
       * -------------------------------------------------------------------------- */
      updateFavs() {
        // On logout, de-select 'favorite' tag remove the fav tag from all dishes
        if (!this.user) {
          this.filter = this.filter === favTag ? '' : this.filter
          this.dishes.forEach((dish) => dish.tags = dish.tags.filter((tag) => tag !== favTag))
        // Otherwise, add / remove fav tags
        } else {
          this.dishes.forEach((dish) => {
            if (!this.fav_dishes.includes(dish.ean)) dish.tags = dish.tags.filter((tag) => tag !== favTag)
            else if (!dish.tags.includes(favTag)) dish.tags.push(favTag)
          })
        }
      },

      /* ========================================================================== *
       * Filter change                                                              *
       * -------------------------------------------------------------------------- */
      filterChange(tag: string) {
        // Normal tags
        if (!tag.includes('secondary')) {
          // Deselect the tag on second click
          if (this.filter === tag) this.filter = ''
          else this.filter = tag
        // GA event for secondary tags
        } else if (!this.filters_without.includes(tag)) {
          const selected = this.tags_secondary[tag][2]
          analyticsEvent('select_filter', { tag_name: selected })
        }
      },

      /* ========================================================================== *
       * Sorting change                                                             *
       * -------------------------------------------------------------------------- */
      sortingChange(key: DishSortingKey) {
        this.sorting_key = key
        analyticsEvent('select_filter', { tag_name: `sort_${key || 'popular'}` })
      },

      /* ========================================================================== *
       * Show more / less filter options                                            *
       * -------------------------------------------------------------------------- */
      toggleFilter(more?: boolean) {
        if (more === undefined) {
          this.filter_open = !this.filter_open
          analyticsEvent(this.filter_open ? 'filters_showmore' : 'filters_showless', {})
        } else analyticsEvent(more ? 'filters_showmore' : 'filters_showless', {})
      },

      /* ========================================================================== *
       * Randomize dish order                                                       *
       * -------------------------------------------------------------------------- */
      shuffleDishes(dishes: Dish[]) {
        for (let i = dishes.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1)) as number
          [ dishes[i], dishes[j] ] = [ dishes[j], dishes[i] ]
        }
        return dishes
      },

      /* ========================================================================== *
       * Add randomize dishes after the listed ones                                 *
       * -------------------------------------------------------------------------- */
      addRestDishes(list: Dish[], rest_dishes: Dish[]) {
        const listed_eans = list.map((dish) => dish.ean)
        const rest = this.shuffleDishes(rest_dishes.filter((dish) => !listed_eans.includes(dish.ean)))
        this.dishes = list.concat(rest)
      },

      /* ========================================================================== *
       * Add the rating scores to the dishes                                        *
       * -------------------------------------------------------------------------- */
      addRatingScores(dish: Dish): Dish {
        if (dishRatings.value) dish.globalRatings = dishRatings.value[dish.ean] || 4
        if (dishRatingsMine.value) dish.myRatings = dishRatingsMine.value[dish.ean] || 4
        return dish
      },
    },
  })
</script>

<style scoped lang="pcss">
.filters {
  @apply flex flex-wrap relative;
  .toggle {
    @apply absolute hidden cursor-pointer bg-white;
    @apply bottom-1 w-full z-10;
    .arrow {
      @apply self-center;
      @apply duration-200 transition-all transform;
      @apply rotate-90 mt-0 ml-2.5 w-2.5;
    }
  }
  &.need_toggle {
    @apply pb-8;
    .toggle {
      @apply flex md:hidden;
    }
  }
  &.with_nutri_pref {
    .filter-wrapper {
      @apply mobile:max-h-0;
    }
    .tags-sorting {
      @apply mobile:max-h-16 mobile:mt-0;
    }
  }
  .filter-wrapper {
    @apply flex flex-nowrap justify-center max-h-[4.1rem];
    @apply -mx-1;
    @apply transition-all duration-300;
    width: calc(100% + 0.5rem);
    .carousel-and-arrows {
      @apply mobile:max-h-[4.25rem] overflow-hidden transition-all duration-300;
    }
    :deep() .tiled {
      .tile {
        @apply mobile:w-auto;
        &.all {
          @apply md:hidden;
          @apply order-1;
        }
        &.favorite {
          @apply md:hidden;
          @apply order-1;
        }
        &.new {
          @apply md:hidden;
          @apply order-3;
        }
        &.back {
          @apply md:hidden;
          @apply order-2;
        }
      }
    }
    .small {
      @apply cursor-pointer;
    }
    .desktop {
      @apply hidden md:flex mx-1 my-2.5;
    }
  }
  /* Order by category */
  :deep() .tile {
    @apply order-last;
    &.back {
      @apply order-2;
    }
    &.new {
      @apply order-3;
    }
    &.highprotein {
      @apply order-4;
    }
    &.favorite {
      @apply order-5;
    }
    &.lowcalorie {
      @apply order-6;
    }
    &.vegetarian {
      @apply order-7;
    }
    &.chicken {
      @apply order-8;
    }
    &.with {
      @apply order-9;
    }
    &.vegan {
      @apply order-10;
    }
    &.planted {
      @apply hidden;
    }
    &.secondary {
      @apply hidden;
    }
  }

  .tags-secondary, .tags-sorting {
    @apply flex flex-wrap overflow-hidden max-h-0 md:max-h-60 -mb-1 md:pt-0 md:mb-0;
    @apply transition-all duration-300 w-full;
    .checkbox-label {
      @apply self-center my-0.5 w-1/3 sm-order:w-auto sm-order:mr-2 order-9;
      .checkbox-checked {
        @apply xl:top-0.5;
      }
      p {
        @apply pl-5 -mt-0.5;
      }
      &.withoutwheat {
        @apply order-1;
      }
      &.withoutmilk {
        @apply order-2;
      }
      &.withoutnuts {
        @apply order-5;
      }
      &.withoutgarlic {
        @apply order-4;
      }
      &.withoutonion {
        @apply order-3;
      }
      &.withoutcoriander {
        @apply order-6;
      }
    }
    .secondary-filter-text {
      @apply text-xs md:text-sm opacity-60 xl:ml-4 my-1 order-last;
      @apply w-full md:w-auto;
    }
  }

  .tags-sorting {
    @apply md:flex-nowrap w-full mt-1.5 mobile:-mx-0.5 md:mb-3;
    width: calc(100% + 0.25rem);
    .sort-text {
      @apply flex-shrink-0 font-bold mx-0.5 md:ml-0 mr-1.5 self-center uppercase;
      @apply w-full md:w-auto;
    }
    :deep() .tile {
      @apply py-0 !px-0.5 mobile:w-auto;
      .tiny {
        @apply cursor-pointer normal-case;
      }
    }
  }

  &.open {
    .filter-wrapper {
      @apply max-h-96;
    }
    .carousel-and-arrows, .tags-secondary, .tags-sorting {
      @apply max-h-60;
    }
    .tags-secondary {
      @apply mobile:pt-1;
    }
    .tags-sorting {
      @apply mobile:mt-1;
    }
    .toggle {
      @apply bottom-1;
    }
    .toggle .arrow {
      @apply -rotate-90;
    }
  }
}
</style>
