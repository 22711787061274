import { nextTick, reactive, watchEffect } from '@vue/runtime-core'
import type { DeliverySlot } from '@juitnow/api-deliveries'
import type { Address, CreateAddress } from '@juitnow/api-addresses-v2'
import { checkoutQueue } from './checkout-queue'
import { analyticsEvent } from '../analytics'
import { client, user } from './client'
import { availableDishes, initializeInventories } from './inventories'
import { ID } from '../content/convert'
import { log, logError } from './log'
import { getTypedEntries } from '../content/client'
import { cartBusy, favDishes } from './state'
import { locale } from './i18n'
import { ProductData, ProductLine, Cart as ServerCart } from '@juitnow/api-orders-v2'
import { TagCategory } from '../widgets/juit-tag-icon'
import { Method } from '@juitnow/api-payments'
import { maintenance_on } from './router'


/* ========================================================================== *
 * TYPES AND CONSTS                                                           *
 * ========================================================================== */

interface CartDish {
  quantity: number,
  ean: string,
  title: string,
  slug: string,
  image: string,
  specialDish: number,
  specialTag: TagCategory,
}
export type NordfrostWeek = {
  cw: number,
  firstDay: Date,
  lastDay: Date,
}

export const reactiveCartKey = 'jn:cart'
export const reactiveCheckoutKey = 'jn:checkout'
export const boxPriceData: Record<number, number> = {}
export const boxPriceDataB2B: Record<number, number> = {}
export const boxIds: ID[] = []
export const boxOptions: number[] = []
export const boxOptionsB2B: number[] = []
export const b2bMax = 640
let pref_box: number
let pref_payment: Method
let timer = setTimeout(() => ({}))


/* ========================================================================== *
 * BOX DATA AND PRICE PER DISH                                                *
 * -------------------------------------------------------------------------- *
 * Box size options, total price and single dish price                        *
 * ========================================================================== */

getTypedEntries('boxList', { locale: 'en' }).then((list) => {
  if (!list) return
  list.items.filter((list) => list.fields.forOrder !== 'none')!.forEach((list) => {
    list.fields.list.forEach((box) => {
      const price = Number(box.fields.description!.replace(/[^0-9.]/g, ''))
      if (list.fields.forOrder === 'B2B') {
        boxPriceDataB2B[box.fields.size] = price
        boxOptionsB2B.push(box.fields.size)
      } else {
        boxPriceData[box.fields.size] = price
        boxOptions.push(box.fields.size)
        boxIds.push(box.sys.id as ID)
      }
    })
  })
  boxOptions.sort((a, b) => a - b)
}).catch((err) => logError(err))


/* ========================================================================== *
 * THE CART                                                                   *
 * -------------------------------------------------------------------------- *
 * Box size, total dish count and dishes in the cart                          *
 * ========================================================================== */

class Cart {
  private __dishes: Record<string, number> = {}
  b2b = false
  box = 0
  boxId = '' as ID
  total = 0
  prefilled_cart_voucher? = ''
  serverCart = undefined as undefined | ServerCart
  outOfStockDishes = [] as string[]


  async addDish(ean: string, quantity = 1, b2b = false): Promise<void> {
    // Update cart b2b value
    this.b2b = b2b
    // If its a full B2C cart, do nothing
    if (!this.b2b && this.total === boxOptions.slice(-1)[0]) return

    // Otherwise, check if an upgrade is needed then set fallback init value and add dish
    if (this.total === this.box) {
      this.box = this.b2b ? boxOptionsB2B[boxOptionsB2B.indexOf(this.box) + 1] : boxOptions[boxOptions.indexOf(this.box) + 1]
      if (!this.b2b) this.boxId = boxIds[boxOptions.indexOf(this.box)]
    }
    this.__dishes[ean] = this.__dishes[ean] || 0
    this.__dishes[ean] += quantity
    analyticsAddRemove(ean, true)
    if (checkoutQueue.init_cart === 'done') {
      clearTimeout(timer)
      timer = setTimeout(async () => {
        await this.updateServerCart(false)
      }, 800)
    }
  }

  async removeDish(ean: string, b2b = false): Promise<void> {
    // Refresh cart b2b value
    this.b2b = b2b
    if (!this.__dishes[ean]) return // No negative
    this.__dishes[ean]--
    analyticsAddRemove(ean, false)
    if (checkoutQueue.init_cart === 'done') {
      clearTimeout(timer)
      timer = setTimeout(async () => {
        await this.updateServerCart(false)
      }, 800)
    }
  }

  removeOutOfStock(): void {
    this.outOfStockDishes.forEach((ean) => delete this.__dishes[ean])
    this.outOfStockDishes = []
  }

  // If the input would exceed the max, return the previous quantity instead
  setDish(ean: string, quantity: number, update?: boolean, b2b = false): void | number {
    // Refresh cart b2b value
    this.b2b = b2b
    const max = this.b2b ? b2bMax : boxOptions.slice(-1)[0]
    const added = quantity - (this.__dishes[ean] || 0)
    if (this.total + added > max) return this.__dishes[ean] || 0
    this.__dishes[ean] = quantity
    if (update) {
      clearTimeout(timer)
      timer = setTimeout(async () => {
        await this.updateServerCart()
      }, 800)
    }
  }

  // Update local changes to server, then update local cart with server response
  async updateServerCart(update = true): Promise<void> {
    if (!this.serverCart || !this.serverCart?.uuid) return
    cartBusy.value.push('')
    log('%cUpdating server-side cart...', 'color: blue')
    try {
      this.serverCart = await client.carts.put(this.serverCart.uuid, { lines: this.lines, locale: locale.value, box_size: this.box, metadata: { boxId: this.boxId } })
      // If an update for local is needed OR we have errors, update the local cart
      if (this.serverCart && (update || this.serverCart.errors?.length)) this.updateLocalCart()
    } catch (e) {
      logError(e)
      nextTick(async () => await this.initServerCart())
    }
  }

  updateLocalCart(): void | string | undefined {
    if (!this.serverCart || !this.serverCart?.uuid) return cartBusy.value.pop()
    log(`%cUpdating local cart with server cart ${this.serverCart.uuid}`, 'color: blue')
    this.clearCart()
    this.box = this.serverCart.box?.size || this.box
    this.boxId = this.serverCart.metadata?.boxId
    this.serverCart.lines.forEach((line) => this.setDish((line as ProductLine).ean, line.quantity, false, !boxOptions.includes(this.serverCart?.box?.size || 0)))
    cartBusy.value.pop()
  }

  // Dish Object for FE display
  get dishes(): CartDish[] {
    return Object.entries(this.__dishes).reduce(function(result, [ ean, quantity ]) {
      if (!quantity) return result
      const match = availableDishes.value.find((dish) => dish.ean === ean)
      if (match) result.push({ ean, quantity, title: match.title, slug: match.slug, image: match.image.src, specialDish: match.specialDish || 0, specialTag: match.specialTag as TagCategory || '' })
      return result
    }, [] as CartDish[])
  }

  // Line Object for server-side cart
  get lines(): ProductData[] {
    return Object.entries(this.__dishes).reduce(function(result, [ ean, quantity ]) {
      if (!quantity) return result
      result.push({ ean, quantity })
      return result
    }, [] as ProductData[])
  }

  // Find the dishes in cart that no longer have a valid stock
  get eans(): string[] {
    return Object.keys(this.__dishes).filter((key) => this.__dishes[key] > 0)
  }

  // Load the cart from local storage
  async initCart(): Promise<void> {
    cartBusy.value.push('')
    const data = localStorage.getItem(reactiveCartKey)
    checkoutQueue.init_cart = 'ongoing'
    if (!data) return await this.initServerCart()
    this.clearCart()
    const cart = JSON.parse(data)
    log('%cInitializing the cart...', 'color: blue', cart)
    this.box = cart.box
    this.boxId = cart.boxId
    this.serverCart = cart.serverCart
    Object.entries(cart.__dishes as Record<string, number>).forEach(([ ean, quantity ]) => this.setDish(ean, quantity, false, cart.b2b))
    nextTick(async () => {
      // If there is no stored server-side cart, create one
      if (!this.serverCart?.uuid) return await this.initServerCart()
      // Otherwise, get stock
      if (checkoutQueue.init_inventory === 'inactive') await initializeInventories()
      cartBusy.value.pop()
    })
  }

  // Create and submit an empty server-side cart
  async initServerCart(): Promise<void> {
    try {
      log('%cLocal cart not found. Creating new server-side cart...', 'color: blue')
      const box_size = this.box ? this.box : this.b2b ? boxOptionsB2B[0] : boxOptions[0]
      this.serverCart = await client.carts.create({ lines: this.dishes.map((dish) => {
        return { 'ean': dish.ean, 'quantity': dish.quantity }
      }), metadata: { boxId: this.boxId }, locale: locale.value, box_size: box_size })
    } catch (e) {
      logError(e)
    } finally {
      if (checkoutQueue.init_inventory === 'inactive') await initializeInventories()
      if (checkoutQueue.init_login === 'done') checkoutQueue.init_cart = 'done'
      cartBusy.value.pop()
    }
  }

  // Clear the cart
  clearCart(): void {
    this.__dishes = {}
    this.total = 0
  }
}

// Load the most recent B2C and B2B server-side carts from the user
async function loadServerCart(): Promise<ServerCart | undefined> {
  const allServerCarts = await client.carts.list()
  if (allServerCarts.length) {
    log('%cServer-side cart loaded', 'color: blue')
    return allServerCarts[0]
  } else {
    const newServerCart = await client.carts.create({ lines: reactiveCart.lines, locale: locale.value, box_size: reactiveCart.box, metadata: { boxId: reactiveCart.boxId } })
    return newServerCart
  }
}

/* ========================================================================== *
 * THE CHECKOUT                                                               *
 * -------------------------------------------------------------------------- *
 * Addresses, delivery slot and payment method                                *
 * ========================================================================== */

class Checkout {
  addresses: {
    email?: string,
    same?: boolean,
    billing?: Address,
    shipping?: Address,
    create_billing?: CreateAddress,
    create_shipping?: CreateAddress,
  } = {}
  slot?: DeliverySlot = undefined
  payment?: Method = undefined
  nordfrost_week?: NordfrostWeek = undefined
  total?: number

  // Load the checkout from local storage
  initCheckout(): void | string {
    const data = localStorage.getItem(reactiveCheckoutKey)
    if (!data) return checkoutQueue.init_checkout = 'done'
    checkoutQueue.init_checkout = 'ongoing'
    log('%cInitializing the checkout...', 'color: blue')
    const checkout = JSON.parse(data)
    this.addresses.email = checkout.addresses.email
    this.slot = checkout.slot
    this.payment = checkout.payment
    if (checkout.nordfrost_week) this.nordfrost_week = { cw: checkout.nordfrost_week.cw, firstDay: new Date(checkout.nordfrost_week.firstDay), lastDay: new Date(checkout.nordfrost_week.firstDay) }
    // Prepare the CreateAddress objects
    if (checkout.addresses.create_billing && checkout.addresses.create_shipping) {
      this.addresses.create_billing = checkout.addresses.create_billing
      this.addresses.create_shipping = checkout.addresses.create_shipping
    }
    checkoutQueue.init_checkout = 'done'
  }

  clearCreateAddress(): void {
    this.addresses.create_billing = undefined
    this.addresses.create_shipping = undefined
  }
}

/* ========================================================================== *
 * CREATE, INITIALIZE AND WATCH THE REACTIVE VARIABLES                        *
 * ========================================================================== */

export const reactiveCart = reactive(new Cart)
export const reactiveCheckout = reactive(new Checkout)
export type ReactiveCart = typeof reactiveCart

nextTick(() => {
  if (maintenance_on) return
  reactiveCart.initCart()
  reactiveCheckout.initCheckout()
})


watchEffect(async () => {
  // Cart: Calculate the total count of dishes
  const quantities = reactiveCart.dishes.map((dish) => dish.quantity)
  reactiveCart.total = quantities.length ? reactiveCart.dishes.map((product) => product.quantity).reduce((prev, next) => prev + next) : 0


  // Cart: Detect dishes no longer have stock
  reactiveCart.outOfStockDishes = reactiveCart.eans.filter((ean) => !reactiveCart.dishes.map((dish) => dish.ean).includes(ean))

  // Cart: Automatically update the box size and ID when switching to B2B
  if (reactiveCart.b2b) {
    reactiveCart.box = boxOptionsB2B.find((box) => (box >= reactiveCart.total)) || boxOptionsB2B.slice(-1)[0]
    reactiveCart.boxId = '' as ID
  }

  // Cart: Wait for the initial login, then update the server-side cart if exist
  if (checkoutQueue?.init_login === 'done' && checkoutQueue?.init_cart === 'ongoing') {
    // If there's an user, load the most recent server-side carts and use it for local
    if (user.value && checkoutQueue.magic_link_login === 'inactive') { // Exclude the case of magic link login, it might have unfinished cart needs to be handled
      log('%cInitial user found. Loading server-side carts...', 'font-weight: bold; color: blue')
      cartBusy.value.push('')
      checkoutQueue.init_cart = 'done'
      const serverCart = await loadServerCart()
      reactiveCart.serverCart = serverCart
      reactiveCart.updateLocalCart()
      cartBusy.value.pop()
    } else {
      log('%cAnonymous user, no server-side cart', 'font-weight: bold; color: blue')
      checkoutQueue.init_cart = 'done'
    }
  }

  // Checkout: Check if billing address should be the same as shipping address EXCEPT THE 'TYPE' AND 'VAT' PROPERTY
  if (reactiveCheckout.addresses?.same) {
    reactiveCheckout.addresses.billing = Object.assign({}, reactiveCheckout.addresses.shipping, { type: 'billing', vat: reactiveCheckout.addresses.billing?.vat, uuid: reactiveCheckout.addresses.billing?.uuid })
    reactiveCheckout.addresses.create_billing = Object.assign({}, reactiveCheckout.addresses.create_shipping, { type: 'billing', vat: (reactiveCheckout.addresses.create_billing as any)?.vat })
  }

  // User related
  if (user.value) {
    // Loading data
    pref_box = user.value.preferences.box_size || 0
    pref_payment = user.value.preferences.payment
    if (checkoutQueue.loading_user_preference === 'pending') {
      reactiveCart.box = reactiveCart.box || pref_box || 0
      favDishes.value = user.value.preferences.favourite_dishes ? [ ...user.value.preferences.favourite_dishes ] : []
      // If we are in the B2C checkout and the default payment method is sepa or invoice, wipe it
      const default_payment = reactiveCheckout.payment || pref_payment
      reactiveCheckout.payment = reactiveCart.b2b || ![ 'sepa_debit', 'invoice' ].includes(default_payment) ? default_payment : undefined
    }

    // Handle the local anonymous cart after manual user login
    if (checkoutQueue.init_cart === 'done' && checkoutQueue.manual_login === 'ongoing') {
      log('%cUser manually logged in. Loading server-side carts...', 'font-weight: bold; color: blue')
      cartBusy.value.push('')
      checkoutQueue.manual_login = 'done'
      // Load the most recent server-side carts from the user
      const serverCart = await loadServerCart()

      // If the local cart is not empty, submit the local cart to the user
      if (reactiveCart.total) {
        log('%cSubmitting local cart to user...', 'font-weight: bold; color: blue', reactiveCart)
        reactiveCart.serverCart = await client.carts.create({ lines: reactiveCart.lines, locale: locale.value, box_size: reactiveCart.box, metadata: { boxId: reactiveCart.boxId } })
        cartBusy.value.pop()
      // Otherwise, use the loaded server-side cart
      } else {
        if (serverCart) reactiveCart.serverCart = serverCart
        else {
          cartBusy.value.push('')
          await reactiveCart.initServerCart()
        }
        reactiveCart.updateLocalCart()
      }
      // Cleanup
      if (checkoutQueue.magic_link_login === 'ongoing') {
        checkoutQueue.magic_link_login = 'done'
        checkoutQueue.login_session = 'done'
        checkoutQueue.init_cart = 'done'
      }
    }

    // Saving data
    if (pref_box && reactiveCart.box && reactiveCart.box !== pref_box) await client.updateUser({ preferences: { box_size: reactiveCart.box } })
    if (pref_payment && reactiveCheckout.payment && reactiveCheckout.payment !== pref_payment) await client.updateUser({ preferences: { payment: reactiveCheckout.payment } })
  }

  // Update local storage
  nextTick(() => { // Put a delay here to wait for initialization
    localStorage?.setItem(reactiveCartKey, JSON.stringify(reactiveCart))
  })
  localStorage?.setItem(reactiveCheckoutKey, JSON.stringify(reactiveCheckout))
})


/* ========================================================================== *
 * ANALYTICS                                                                  *
 * ========================================================================== */
function analyticsAddRemove(ean: string, add: boolean): void {
  analyticsEvent(add ? 'add_to_cart' : 'remove_from_cart', {
    items: [ {
      item_name: availableDishes.value.find((dish) => dish.ean === ean)?.title || '',
      item_id: ean,
      quantity: 1,
    } ],
  })
}
