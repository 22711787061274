import { Document } from '@contentful/rich-text-types'
import { Asset, Entry } from 'contentful'
import * as Types from './types'
import { TagCategory } from '../widgets/juit-tag-icon'

interface Image {
  title: string,
  alt: string,
  src: string,
  contentType: string,
  [ key: string ]: any, // v-bind requires a Record<string, unknown>
}

interface CTA {
  slug?: string,
  text?: string,
}

export interface PreFilledCart {
  slug: string,
  list: Dish[],
  bundleSize: number,
  voucher?: string,
  title: string,
}

type Title = string & { __brand_title: any }
export type ID = string & { __brand_id: any }

/* ========================================================================== *
 * INTERNAL TYPES                                                             *
 * ========================================================================== */

/** Extract the type ID from a Contentful entry type */
type ContentfulTypeID<T extends Entry<any>> = T['sys']['contentType']['sys']['id']

/** Associate one of our interfaces with a Contentful entry type */
interface Typed<T extends Entry<any> = Entry<any>> {
  $type: ContentfulTypeID<T>,
  $data: T,
  $id: ID,

  title: Title,
}

/* ========================================================================== *
 * ATOMS                                                                      *
 * ========================================================================== */

export interface Box extends Typed<Types.IBox> {
  description?: string,
  size: number,
  cta?: CTA,
  label?: string,
}

export interface IconText extends Typed<Types.IIconText> {
  description?: Document,
  image?: Image,
}

export interface PressLogo extends Typed<Types.IPressLogo> {
  logo: Image,
  url?: string,
}

export interface QA extends Typed<Types.IQa> {
  category: Types.IQaFields['category'],
  description?: Document,
}

export interface Testimonial extends Typed<Types.ITestimonial> {
  description?: Document,
  image: Image,
  subtitle: string,
}

/* ========================================================================== *
 * MODULES                                                                    *
 * ========================================================================== */

export interface BoxList extends Typed<Types.IBoxList> {
  description?: string,
  showPref?: boolean,
  list: Box[],
}

export interface DataContent extends Typed<Types.IDataContent> {
  description?: Document,
  moduleType: 'Contact' | 'Careers' | 'App Download CTA'| 'Subscribe',
  contentBottom?: Document,
  image?: Image,
}

export interface DishCarousel extends Typed<Types.IDishCarousel> {
  description?: Document,
  list: Dish[],
  listOnly: boolean,
  availableOnly: boolean,
  showFilter: boolean,
  cta?: CTA,
}

export interface DishShowcase extends Typed<Types.IDishShowcase> {
  description?: Document,
  list: Dish[],
  listOnly: boolean,
  availableOnly: boolean,
  showFilter: boolean,
  cta?: CTA,
}

export interface IconTextList extends Typed<Types.IIconTextList> {
  image: Image,
  list: IconText[],
  cta?: CTA,
  layout: 'Simple' | 'Panel-like',
}

export interface QAList extends Typed<Types.IQaList> {
  layout: 'Simple' | 'Categorized',
  list: QA[],
}
export interface TestimonialList extends Typed<Types.ITestimonialList> {
  list: Testimonial[],
}

export interface Panel extends Typed<Types.IPanel> {
  description?: Document,
  frame?: boolean,
  image: Image,
  shrink?: boolean,
  preFilledCarts?: PreFilledCart[],
  cta?: CTA,
  ctaExternal?: CTA,
  globalRating?: boolean,
  socialMediaLinks?: boolean,
}

export interface PanelList extends Typed<Types.IPanelList> {
  list: Panel[],
}

export interface PressLogoList extends Typed<Types.IPressLogoList> {
  list: PressLogo[],
}
export interface UserRatings extends Typed<Types.IUserRatings> {}

/* ========================================================================== *
 * DISHES                                                                      *
 * ========================================================================== */

export interface Dish extends Typed<Types.IDish> {
  secondTitle?: string | undefined;
  ean: string;
  sellable: boolean;
  slug: string;
  image: Image;
  extraImages?: Image[];
  panels?: any,
  description: Document;
  ingredients: Document;
  preparation?: Document | undefined;
  moreInfo?: string;
  storage?: string;
  alwaysDisplay?: boolean | undefined;
  nutriScore?: 'A' | 'B' | 'C' | 'D' | 'E' | undefined;
  portions?: number | undefined;
  calories: number;
  weight: number;
  fat: number;
  saturatedFat: number;
  carb: number;
  sugar: number;
  fiber: number;
  protein: number;
  salt: number;
  vitaminB12?: number | undefined;
  microwave_time_in_minutes?: number | undefined;
  microwave_watts?: number | undefined;
  tags: string[],
  specialTag?: TagCategory;
  specialDish?: number;
  specialDishDescription?: string;
  myRatings?: number;
  globalRatings?: number;
  recommended?: number;
  soldout: boolean;
  soldoutInfo?: string;
}

export interface DishList extends Typed<Types.IDishList> {
  list: Dish[],
  listOnly: boolean,
  showFilters: boolean,
}

/* ========================================================================== *
 * PAGES                                                                      *
 * ========================================================================== */

export type Module =
  | BoxList
  | DataContent
  | DishCarousel
  | DishShowcase
  | IconTextList
  | QAList
  | TestimonialList
  | Panel
  | PanelList
  | PressLogoList
  | UserRatings

export interface Page extends Typed<Types.IPage> {
  slug: string,
  metaTitle?: string,
  metaDescription?: string,
  hero?: Panel,
  modules?: Module[],
  content?: Document,
}

export interface List extends Typed<Types.IList> {
  slug: string,
  links: Page[],
}

/* ========================================================================== *
 * CONVERSION TYPES                                                           *
 * ========================================================================== */

/** All the types we convert to (our types) */
type Type =
  | Box
  | Dish
  | DishList
  | IconText
  | QA
  | Testimonial
  | BoxList
  | DataContent
  | DishCarousel
  | DishShowcase
  | IconTextList
  | QAList
  | TestimonialList
  | Panel
  | PanelList
  | Page
  | PressLogo
  | PressLogoList
  | List
  | UserRatings

/** Extract the ID of the type from one of our types */
type TypeID<T extends Typed> = T['$type']

/** A type mapping IDs to our (converted) types */
type TypeMapping = {
  [ T in Type as TypeID<T> ] : T
}

/** Extract the Contentful type from one of our types */
type ContentfulTyped<T> = T extends Typed<infer C> ? C : never

/** All the types we convert from (Contentful types) */
type ContentfulType = ContentfulTyped<Type>

/** A type mapping IDs to our (converted) types */
type ContentfulTypeMapping = {
  [ T in Type as TypeID<T> ] : ContentfulTyped<T>
}

/** A converted type for the original Contentful type */
type ConvertedType<C extends ContentfulType> = TypeMapping[ContentfulTypeID<C>]

/** The props for a converted type (all but "$type" and "$data") */
type ConvertedProps<C extends ContentfulType> = Omit<ConvertedType<C>, '$id' | '$type' | '$data' | 'title'>

/** Converters from Contentful to our types, one per type ID */
type Converters = {
  [ T in TypeID<Type> ]: (data: ContentfulTypeMapping[T]) => TypeMapping[T]
}

/* ========================================================================== *
 * CONTENT CONVERSION                                                         *
 * ========================================================================== */

/** Filter out null/undefined from an array */
function voidFilter<T>(array: (T | undefined | null)[]): T[] {
  return array.filter((t) => (t !== null) && (t !== undefined)) as T[]
}

/** Create a converted type from the original Contentful entry and converted props */
function create<C extends ContentfulType>(data: C, callback: () => ConvertedProps<C>): ConvertedType<C> {
  const props = callback()
  return {
    ...props,
    $type: data.sys.contentType.sys.id,
    $data: data,
    $id: data.sys.id,
    title: title(data.fields.title),
  } as unknown as ConvertedType<C>
}

/** Convert the title, trimming it and replacing the paragraph mark */
function title(title: string | undefined = ''): Title {
  return title.trim().replace(/\u00b6/g, '\n') as Title
}

/** Convert a contentful Asset into our own Image */
function image(image: Asset): Image
function image(image?: Asset | undefined): Image | undefined
function image(image?: Asset | undefined): Image | undefined {
  if (!image?.fields || !image.fields.title || !image.fields.file ) return undefined
  return {
    title: image.fields.title,
    alt: image.fields.description,
    src: image.fields.file.url,
    contentType: image.fields.file.contentType,
  }
}

/** Combine CTA page and text into a single CTA object */
function cta(fields: { cta: Types.IPage | Types.IDish | Types.IPrefilledCart, ctaText?: string }): CTA
function cta(fields: { cta?: Types.IPage | Types.IDish | Types.IPrefilledCart, ctaText?: string }): CTA | undefined
function cta(fields: { cta?: Types.IPage | Types.IDish | Types.IPrefilledCart, ctaText?: string }): CTA | undefined {
  const contentSlug = fields.cta?.fields?.slug
  const slug =
    fields.cta?.sys.contentType?.sys.id === 'prefilledCart' ? `carts/${contentSlug}` :
    fields.cta?.sys.contentType?.sys.id === 'dish' ? `dishes/${contentSlug}` :
    fields.cta?.sys.contentType?.sys.id === 'page' ? contentSlug :
    undefined

  const text = fields.ctaText ? fields.ctaText : fields.cta?.fields.title

  return (slug || text) ? { slug, text } : undefined
}

function ctaExternal(fields: { ctaExternal?: string, ctaText?: string }): CTA | undefined {
  if (! fields.ctaExternal) return undefined
  const slug = fields.ctaExternal
  const text = fields.ctaText || ''
  return { slug, text }
}

/** Convert an array of Contentful entry into an array of one of our types */
function array<C extends ContentfulTyped<Type>>(data: C[]): ConvertedType<C>[]
function array<C extends ContentfulTyped<Type>>(data?: C[] | undefined): ConvertedType<C>[] | undefined
function array<C extends ContentfulTyped<Type>>(data?: C[] | undefined): ConvertedType<C>[] | void {
  if (! data) return
  return voidFilter(data.map((value) => convert(value)))
}

/** Converters, keyed by type ID */
const converters: Converters = {
  // ATOMS

  box: (data) => create(data, () => ({
    ...data.fields,
    cta: cta(data.fields),
    label: data.fields.specialLabel?.replaceAll(' ', '').toLowerCase(),
  })),

  iconText: (data) => create(data, () => ({
    ...data.fields,
    image: image(data.fields.image),
  })),

  qa: (data) => create(data, () => ({
    ...data.fields,
  })),

  testimonial: (data) => create(data, () => ({
    ...data.fields,
    subtitle: data.fields.subtitle,
    image: image(data.fields.image),
  })),

  // MODULES

  boxList: (data) => create(data, () => ({
    ...data.fields,
    list: array(data.fields.list),
  })),

  dataContent: (data) => create(data, () => ({
    ...data.fields,
    image: image(data.fields.image),
  })),

  dishCarousel: (data) => create(data, () => ({
    ...data.fields,
    cta: cta(data.fields),
    list: array(data.fields.dishList?.fields.links),
    listOnly: data.fields.dishList?.fields.listedOnly,
    availableOnly: !data.fields.dishList?.fields.ignoreAvailability,
    showFilter: data.fields.dishList?.fields.showFilters,
  })),

  dishShowcase: (data) => create(data, () => ({
    ...data.fields,
    cta: cta(data.fields),
    list: array(data.fields.dishList?.fields.links),
    listOnly: data.fields.dishList?.fields.listedOnly,
    availableOnly: !data.fields.dishList?.fields.ignoreAvailability,
    showFilter: data.fields.dishList?.fields.showFilters,
  })),

  iconTextList: (data) => create(data, () => ({
    ...data.fields,
    cta: cta(data.fields),
    list: array(data.fields.list),
    image: image(data.fields.image),
  })),

  qaList: (data) => create(data, () => ({
    ...data.fields,
    list: array(data.fields.list),
  })),

  testimonialList: (data) => create(data, () => ({
    ...data.fields,
    list: array(data.fields.list),
  })),

  panel: (data) => create(data, () => ({
    ...data.fields,
    image: image(data.fields.image),
    cta: cta(data.fields),
    preFilledCarts: data.fields.preSelectedBoxes?.map((box) => {
      return {
        'slug': box.fields.slug,
        'list': array(box.fields.products),
        'voucher': box.fields.voucher,
        'bundleSize': box.fields.bundleSize,
        'title': box.fields.title,
      }
    }),
    ctaExternal: ctaExternal(data.fields),
  })),

  panelList: (data) => create(data, () => ({
    ...data.fields,
    list: array(data.fields.list),
  })),

  pressLogo: (data) => create(data, () => ({
    ...data.fields,
    logo: image(data.fields.logo),
  })),

  pressLogoList: (data) => create(data, () => ({
    ...data.fields,
    list: array(data.fields.list),
  })),

  userRatings: (data) => create(data, () => ({
    ...data.fields,
  })),

  // PAGES
  page: (data) => create(data, () => ({
    ...data.fields,
    hero: convert(data.fields.heroNew),
    modules: array(data.fields.modules),
  })),

  list: (data) => create(data, () => ({
    ...data.fields,
    links: array(data.fields.links),
  })),

  // DISHES
  dish: (data) => create(data, () => ({
    ...data.fields,
    image: image(data.fields.image),
    extraImages: data.fields.extraImages?.map((img) => image(img)),
    tags: data.metadata.tags.map((tag) => tag.sys.id),
    carb: data.fields.carbohydrate,
    panels: array(data.fields.panels),
    calories: data.fields.energy,
    more_info: data.fields.moreInfo,
    storage: data.fields.storage,
    preparation: data.fields.preparation,
    alwaysDisplay: data.fields.alwaysDisplay,
    portions: data.fields.portions,
    soldout: false,
    soldoutInfo: data.fields.soldOutInfo,
  })),

  dishList: (data) => create(data, () => ({
    ...data.fields,
    list: array(data.fields.links),
    listOnly: data.fields.listedOnly,
    showFilter: data.fields.showFilters,
  })),

}

/* ========================================================================== */

/** Convert a Contentful entry into one of our types  */
export function convert<C extends ContentfulTyped<Type>>(data: C): ConvertedType<C> | undefined
export function convert<C extends ContentfulTyped<Type>>(data?: C | undefined): ConvertedType<C> | undefined
export function convert<C extends ContentfulTyped<Type>>(data?: C | undefined): ConvertedType<C> | void {
  if (! data) return
  if (! data.sys.contentType) return
  const type = data.sys.contentType.sys.id
  const converter = converters[type]
  if (! converter) throw new Error(`Unable to convert entry of type "${type}"`)
  return converter(data as any) as any
}
