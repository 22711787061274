<!--==================================================================+
| VIEW: DISH SELECTION                                                |
+===================================================================-->
<template>
  <main class="dish-selection">
    <section v-if="available_dishes && preferred_products" class="relative">
      <div class="container">
        <div class="text-top">
          <h1 class="mt-0 mb-2">
            {{ $t( 'order.header' ) }}
          </h1>
        </div>
        <div class="md:pr-[300px] lg:pr-[320px] xl-order:pr-[360px] xxl:pr-[440px]">
          <!--==================================================================+
          | DISH FILTERS                                                        |
          +===================================================================-->
          <juit-tags-filter
            v-if="available_dishes"
            :orderflow="true"
            :list="preferred_products"
            :all-dishes="available_dishes"
            :advanced-filters="true"
            @filtered="filtered_products = $event"
          />

          <!--==================================================================+
          | DISHES                                                              |
          +===================================================================-->

          <!-- DISHES -->

          <div v-if="filtered_products?.length" class="tiled">
            <juit-submitting :visible="!filtered_products" class="mt-12" />
            <div
              v-for="product in filtered_products"
              v-show="(product.specialTag !== 'Extra') && (product.specialTag !== 'Sweets')"
              :key="product.ean"
              class="tile group"
            >
              <juit-dish-card :product="product" />
            </div>
          </div>

          <!-- EXTRAS (includes Sweets) -->

          <h1 v-if="available_extras" class="pt-8 md:pt-12 pb-0">
            {{ $t('dishes.extras_title') }}
          </h1>

          <p v-if="available_extras" class="pt-0 pb-5">
            {{ $t('dishes.extras_subtitle') }}
          </p>

          <div v-if="filtered_products?.length" class="tiled">
            <div
              v-for="product in filtered_products"
              v-show="(product.specialTag === 'Extra') || (product.specialTag === 'Sweets')"
              :key="product.ean"
              class="tile group"
            >
              <juit-dish-card :product="product" />
            </div>
          </div>
        </div>
      </div>


      <!--==================================================================+
      | CART                                                                |
      +===================================================================-->
      <juit-cart v-if="available_dishes" :products="available_dishes" />
    </section>
  </main>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import juitTagsFilter from '../widgets/juit-tags-filter.vue'
  import juitDishCard from '../components/order/dish-card.vue'
  import juitCart from '../components/order/cart.vue'
  import { boxOptions, reactiveCart } from '../init/reactive-order'
  import alertModal from '../modals/alert-modal.vue'
  import { availableDishes, visibilityCheckAll, visibilityCheck } from '../init/inventories'
  import { Dish, fetchProducts } from '../content'
  import { locale } from '../init/i18n'
  import { log, logError } from '../init/log'
  import { checkoutQueue } from '../init/checkout-queue'

  export default defineComponent({
    components: {
      juitTagsFilter,
      juitDishCard,
      juitCart,
    },

    data: () => ({
      preferred_products: undefined as undefined | Dish[],
      filtered_products: undefined as undefined | Dish[],
    }),

    computed: {
      available_dishes() {
        return availableDishes.value as Dish[]
      },

      watch_for() {
        return [ checkoutQueue.init_inventory, locale.value ]
      },

      // we check if there are any extras, to show/hide the title
      available_extras() {
        if (!this.filtered_products) return false
        return this.filtered_products.some((dish) => ((dish.specialTag === 'Extra') || (dish.specialTag === 'Sweets')))
      },
    },

    watch: {
      /* ========================================================================== *
       * Fetch products on locale change and after inventory loaded                 *
       * -------------------------------------------------------------------------- */
      watch_for: {
        async handler() {
          if (checkoutQueue.init_inventory === 'done') {
            await visibilityCheckAll()
            fetchProducts(locale.value, 'Order Page Most Popular').then((dishes) => {
              if (dishes) this.preferred_products = visibilityCheck(dishes)
              log('%cPreferred available dishes:', 'color: firebrick', this.preferred_products)
            }).catch((error) => logError(`Error fetching products (locale="${locale}")`, error))

            // If some dishes from the reactive cart are unavailable, show a modal
            if (reactiveCart.outOfStockDishes.length) {
              await this.$createModal(alertModal as any, {
                title: this.$t('checkout.not-available'),
                description: this.$t('checkout.dish-not-available'),
                url: '/order',
                locked: true,
              })
              // we remove the dishes that are out of stock
              reactiveCart.removeOutOfStock()
            }
          }
        },
        deep: true,
        immediate: true,
      },
    },

    mounted() {
      // No selected b2c box, go back to /box
      this.$nextTick(() => {
        if (!reactiveCart.box || !boxOptions.includes(reactiveCart.box)) return this.$router.push('/box')
      })
    },

  })
</script>

<style scoped lang="pcss">
  .dish-selection {
    @apply w-screen overflow-hidden pb-36 md:pb-16;
    .tiled {
      @apply -mx-1 -my-0 flex-wrap md:-mt-1;
      /* Single card */
      .tile {
        @apply mx-0 p-1;
        @apply sm-order:w-1/2 lg-order:w-1/3 xl-order:w-1/4;
      }
    }
  }
</style>
