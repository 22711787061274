/* ========================================================================== *
 * VUE ROUTER                                                                 *
 * -------------------------------------------------------------------------- *
 * Main router for our app.                                                   *
 * ========================================================================== */
import PageComponent from '../layout/page.vue'
import CheckoutComponent from '../layout/checkout.vue'
import OrderComponent from '../layout/order.vue'
import OrderB2BComponent from '../layout/order-b2b.vue'
import MaintenanceComponent from '../layout/maintenance.vue'
import {
  createRouter,
  createWebHistory,
  RouteLocationRaw,
  RouteRecordRaw,
} from 'vue-router'

import { browserLocale, isLocale, locale, setLocale } from './i18n'
import { env } from './env'
import { fetchProduct, fetchList, fetchPage, Page, Dish, fetchAlternateSlug } from '../content'
import { defineAsyncComponent, ref, watch } from '@vue/runtime-core'
import { log } from './log'
import { analyticsEvent } from '../analytics'
import { availableDishes, initializeInventories } from '../init/inventories'
import { nextTick } from 'vue'
import { title } from './meta'
import { LOCALE_CODE } from '../content/types'
import { user } from './client'
import { userCohort } from './state'

/* ======================================================================== *
 | THE KILL SWITCH FOR MAINTAINENCE                                         |
 * ======================================================================== */
export const maintenance_on = false


/* async components - used less often, incur a delay in loading */
const DishComponent = defineAsyncComponent(() => import('../layout/dish.vue'))
const RatingComponent = defineAsyncComponent(() => import('../layout/ratings.vue'))
const ContactComponent = defineAsyncComponent(() => import('../layout/contact-us.vue'))
const ProfileComponent = defineAsyncComponent(() => import('../layout/profile.vue'))
const PrefillCartComponent = defineAsyncComponent(() => import('../layout/prefilled-cart.vue'))
const ResetEmailComponent = defineAsyncComponent(() => import('../layout/reset-email.vue'))
const ResetPasswordComponent = defineAsyncComponent(() => import('../layout/reset-password.vue'))
const ThankyouComponent= defineAsyncComponent(() => import('../layout/thankyou.vue'))

const routes: RouteRecordRaw[] = [ {
  /* ======================================================================== *
   | NORMAL ROUTES                                                            |
   * ======================================================================== */

  // Home page
  name: 'home',
  path: '/:language',
  component: PageComponent,
  props: (to) => ({ page: to.meta.page, alternateSlug: to.meta.alternateSlug }),
}, {

  // Cart Prefiller,
  name: 'carts',
  path: '/:language/carts/:slug',
  component: PrefillCartComponent,
  props: (to) => ({ slug: to.params.slug }),
}, {

  // Contact Page,
  name: 'contact',
  path: '/:language/contact',
  component: ContactComponent,
}, {

  // Profile page
  name: 'profile',
  path: '/:language/profile/:section',
  component: ProfileComponent,
  meta: { page: 'profile/referral' }, // So we get the FAQs from /referral in contentful
  props: (to) => ({ page: to.meta.page }),
}, {
  path: '/:language/profile',
  redirect: (to) => ({
    path: `/${to.params.language}/profile/dashboard`,
  }),
}, {

  // Reset Password page
  name: 'reset-password',
  path: '/:language/reset-password',
  component: ResetPasswordComponent,
}, {
  path: '/:language/profile/reset-password',
  redirect: (to) => ({
    path: `/${to.params.language}/reset-password`,
  }),
}, {

  // Reset Email page
  name: 'reset-email',
  path: '/:language/reset-email',
  component: ResetEmailComponent,
}, {
  path: '/:language/profile/reset-email',
  redirect: (to) => ({
    path: `/${to.params.language}/reset-email`,
  }),
}, {

  // Dish Selection Page,
  name: 'order',
  path: '/:language/order',
  component: OrderComponent,
}, {
  name: 'order-b2b',
  path: '/:language/business/order',
  component: OrderB2BComponent,
}, {
  path: '/:language/order-b2b',
  redirect: (to) => ({
    path: `/${to.params.language}/business/order`,
    query: to.query,
    hash: to.hash,
  }),
}, {

  // Checkout Page,
  name: 'checkout',
  path: '/:language/checkout',
  component: CheckoutComponent,
}, {
  name: 'checkout-b2b',
  path: '/:language/business/checkout',
  component: CheckoutComponent,
}, {

  // Thankyou Page,
  name: 'thankyou',
  path: '/:language/thankyou',
  component: ThankyouComponent,
}, {
  name: 'thankyou-b2b',
  path: '/:language/business/thankyou',
  component: ThankyouComponent,
}, {

  // Rating Page
  name: 'rating',
  path: '/:language/rating/:product',
  component: RatingComponent,
  props: (to) => ({ product: to.meta.product, lot: to.query.lot }),
}, {

  // Normal page
  name: 'page',
  path: '/:language/:page',
  component: PageComponent,
  props: (to) => ({ page: to.meta.page, alternateSlug: to.meta.alternateSlug }),
}, {
  name: 'business-page',
  path: '/:language/business/:page',
  component: PageComponent,
  props: (to) => ({ page: to.meta.page, alternateSlug: to.meta.alternateSlug }),
}, {

  // Product Page
  path: '/:language/dishes/:product',
  component: DishComponent,
  props: (to) => ({ product: to.meta.product, alternateSlug: to.meta.alternateSlug }),
}, {
  path: '/:language/gerichte/:product',
  component: DishComponent,
  props: (to) => ({ product: to.meta.product, alternateSlug: to.meta.alternateSlug }),
}, {

  // Maintainence page
  path: '/maintenance',
  name: 'maintenance-page',
  component: MaintenanceComponent,
}, {

  // Support the "old-style" landing pages redirecting them to their normal url
  path: '/:language/landing/:slug',
  redirect: (to) => ({
    path: `/${to.params.language}/${to.params.slug}`,
    query: to.query,
    hash: to.hash,
  }),

  // Redirect everything else to home page
}, {
  path: '/:any(.*)',
  redirect: (to) => ({
    path: `/${locale.value}`,
    query: to.query,
    hash: to.hash,
  }),
} ]

/** Our main router instance */
export const router = createRouter({
  history: createWebHistory(),
  // Scroll behavior, always scroll to the top
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition
    return { top: 0, left: 0 }
  },
  routes,
})

/** The _header_ for our website, always fetched and updated on locale change */
export const header = ref([] as Page[])
/** The _business_header_ for our website, always fetched and updated on locale change */
export const business = ref([] as Page[])
/** The _footer_ for our website, always fetched and updated on locale change */
export const footer = ref([] as Page[])

/* ========================================================================== *
 * NAVIGATION GUARDS                                                          *
 * ========================================================================== */

// Keep track on when the router is busy, to track locale changes below. The
// initial state is `true`: unavailable until the first route is processed
let routerBusy = true

// This is our main navigation guard, validating our route (for locale) and
// parallelizing our calls to the network to speed up loading time.
//
// What we do, in details, is:
// 1) validate our locale (or redirect to the home)
// 2) figure out if we need a page (from the "page" parameter)
// 3) figure out if we need a product (from the "product" parameter)
// 4) parallel download translations, header, footer, page, product(s), ...
// 5) set translations, and verify 404s for pages / products
router.beforeEach(async (to) => {
  routerBusy = true
  log('Router to ', to)

  if (maintenance_on) {
    if (to.name !== 'maintenance-page') return ('/maintenance')
  } else if (to.name !== 'maintenance-page') {
    // If not the correct locale, redirect _with_ the correct locale from the
    // browser... This allows us to have url like https://www.juit.com/dishes and
    // automatically negotiate the correct language
    const language = to.params.language as string
    if (! isLocale(language)) {
      return {
        path: `/${locale.value || browserLocale}${to.fullPath}`,
        query: to.query,
        hash: to.hash,
        section: to.params.section,
      }
    }

    // Prune the path if something (like paypal) was adding query after hash
    if (to.query && ! to.hash && to.path.includes('#')) return to.path

    // ---------------------------------------------------------------------------
    // PAGES
    // ---------------------------------------------------------------------------
    let page
    if (to.name === 'home') {
      page = env.VITE_CONTENTFUL_HOME_SLUG
    } else if (to.params.page === env.VITE_CONTENTFUL_HOME_SLUG) {
      return `/${language}`
    } else if (to.params.page) {
      page = to.params.page as string
    } else if (to.meta.page) {
      page = to.meta.page as string
    }

    // ---------------------------------------------------------------------------
    // PRODUCTS
    // ---------------------------------------------------------------------------

    const product = to.params.product as string
    const dishes_text = language === 'en' ? 'dishes' : 'gerichte'

  // ---------------------------------------------------------------------------
  // PARALLELIZE NETWORK ACCESS
  // ---------------------------------------------------------------------------
  ;([
      header.value,
      footer.value,
      business.value,
      to.meta.page,
      to.meta.product,
      to.meta.alternateSlug,
    ] = await Promise.all([
      fetchList(language, 'header').then((list) => list ? list.links : []),
      fetchList(language, 'footer').then((list) => list ? list.links : []),
      fetchList(language, 'business').then((list) => list ? list.links : []),
    page ? (to.name === 'business-page') ? fetchPage(language, `business/${page}`) : fetchPage(language, page) : undefined,
    product ? fetchProduct(language, product) : undefined,
    fetchAlternateSlug(locale.value, to.name === 'business-page' ? `business/${page}` : page || product, page ? 'page' : 'dish', to.name === 'rating'),
    setLocale(language), // ignore result
    ]))

    // Potential 404s redirected to the home page!
    if (page && to.name === 'business-page' && (! to.meta.page)) return `/${language}/business`
    if (page && (! to.meta.page)) return `/${language}`
    if (product && (! to.meta.product)) return `/${language}/${dishes_text}`

    // Resolve the slug localisation
    if (page && page !== (to.meta.page as Page).slug.split('/').pop() && to.name !== 'profile') return `/${language}/${(to.meta.page as Page).slug}`
    if (product && to.name !== 'rating') {
      if (product !== (to.meta.product as Dish).slug || to.fullPath.split('/')[2] !== dishes_text) return `/${language}/${dishes_text}/${(to.meta.product as Dish).slug}`
    }

    // ---------------------------------------------------------------------------
    // A-B TEST REDIRECT: COHORT 1-6 AS CONTROL
    // ---------------------------------------------------------------------------
    // Redirect different groups to their corresponding urls
    const slugs = {
      control: 'more-time-for-you',
      test: 'more-time-for-you-b',
    }
    const to_with_params = {
      control: { path: `/${locale.value}/${slugs.control}`, hash: to.hash, query: to.query },
      test: { path: `/${locale.value}/${slugs.test}`, hash: to.hash, query: to.query },
    }
    if ((userCohort.value >= 7) && urlCheck(to.path, slugs) === 'control') return to_with_params.test
    if ((userCohort.value < 7) && urlCheck(to.path, slugs) === 'test') return to_with_params.control
  }
})

function urlCheck(path: string, ref: Record<string, string>): string | undefined {
  const pruned = path.replaceAll('/', '') // In case there is an extra '/' at the end of url
  if (pruned === `${locale.value}${ref.control}`) return 'control'
  if (pruned === `${locale.value}${ref.test}`) return 'test'
}


// The last HREF seen by the router
let lastPageView = ''

router.afterEach(async (to) => {
  routerBusy = false

  // Push the `page_view` event in the next tick, so that things like
  // page title and metadata can be correctly set by the components
  nextTick(() => {
    if (window.location.href !== lastPageView) {
      lastPageView = window.location.href
      analyticsEvent('page_view', {
        // Remove the last '/' from the url when submitting page_view event to GA
        page_location: window.location.href.endsWith('/') ? window.location.href.slice(0, -1) : window.location.href,
        page_title: title.value,
      })
    }
  })

  // Load dish inventories on order / checkout page / profile page
  const router_name = to.name?.toString()
  if (router_name && (router_name.includes('order') || router_name.includes('checkout') || router_name.includes('profile')) && !availableDishes.value.length) await initializeInventories()
})

/* ========================================================================== *
 * LOCALE CHANGES: watch locale and (possibly) route to the correct page      *
 * ========================================================================== */

watch(locale, (locale) => {
  routerFixWithLocale(locale)
})

function routerFixWithLocale(locale: LOCALE_CODE): void {
  if (routerBusy) return // only if not navigating...
  const route = router.currentRoute.value
  const language = route.params.language
  const section = route.params.section
  const alternateSlug = route.meta.alternateSlug as string
  if (language && (language != locale)) {
    log('Automatically changing page language to', locale)
    void router.push({ ...route, path: alternateSlug, params: { language: locale, section: section } } as RouteLocationRaw)
  }
}

/* ========================================================================== *
 * USER LOGIN: correct page locale with user locale                           *
 * ========================================================================== */

watch(user, (user, oldUser) => {
  if (user?.uuid && user.uuid !== oldUser?.uuid) setTimeout(() => routerFixWithLocale(user.locale), 500)
}, { immediate: true })
