/* ========================================================================== *
 * LOG WRAPPER - wrap (and disable in production) console.log                 *
 * ========================================================================== */
/* eslint-disable no-console */

import { env } from './env'

/** The logging function, disabled in production */
export const log: typeof console.log =
  (env.DEV || (env.MODE === 'development')) ? console.log : () => {}

export const logGroupCollapsed: typeof console.groupCollapsed =
  (env.DEV || (env.MODE === 'development')) ? console.groupCollapsed : () => {}

export const logGroupEnd: typeof console.groupEnd =
  (env.DEV || (env.MODE === 'development')) ? console.groupEnd : () => {}

/** The error logging function, always enabled */
export const logError: typeof console.error = console.error
