import { watch } from '@vue/runtime-core'
import { ComponentPublicInstance } from 'vue'

import { analyticsEvent, initializeAnalytics } from './analytics'
import { checkoutQueue } from './init/checkout-queue'
import { initializeDishRatings } from './init/ratings'
import { privacyTier } from './analytics/privacy'
import { createJuitApp } from './app'
import { accessTokenKey, accessTokenRef, client, refreshToken, refreshTokenKey, refreshTokenRef, user, userRef } from './init/client'
import { setLocale } from './init/i18n'
import { log, logError } from './init/log'
import { maintenance_on, router } from './init/router'
import { Error } from '@juitnow/lib-schema/types'

import alertModal from './modals/alert-modal.vue'
import cookiesModal from './modals/cookies/cookies-modal.vue'

import './init/survicate'
import './style.css'
import { initCohort } from './init/cohort'
import { reactiveCart, reactiveCartKey } from './init/reactive-order'
import { loadStripe } from '@stripe/stripe-js'
import { env } from './init/env'
import { applePayCapabilitiesCheck } from './init/stripe-checkout'

/* ========================================================================== *
 * Timestamp check and auto reload                                            *
 * ========================================================================== */

let currentTimestamp = ''
const $location = globalThis.window.location

async function checkTimestamp(): Promise<void> {
  try {
    const res = await fetch(`/timestamp.json?t=${Date.now()}`, { method: 'get' })
    if (!res) return // Obviously, Sentry told us that something COULD block the client from fetching the timestamp
    const new_timestamp = await res.text()
    log('Timestamp:', new_timestamp)
    if (!currentTimestamp) currentTimestamp = new_timestamp
    else if (currentTimestamp !== new_timestamp) $location.reload()
  } catch (error) {
    logError('ERROR FETCHING TIMESTAMP:', error)
  }
}
if (
  $location.hostname !== 'localhost' &&
  $location.hostname !== 'dev.juit.com' &&
  $location.hostname !== 'dev2.juit.com' &&
  $location.hostname !== '127.0.0.1'
) {
  checkTimestamp()
  setInterval(checkTimestamp, 1000 * 60)
}

/* ========================================================================== *
 * API client: synchronize refresh token with local storage                   *
 * ========================================================================== */

async function initialLogin(): Promise<void> {
  // Login with magic link
  const url = ($location.hash.slice($location.hash.lastIndexOf('#'))).replace('#', '?')
  const url_params = new URLSearchParams(url)
  const map = [] as string[][]
  for (const [ key, value ] of url_params) map.push([ key, value ])
  const params = map.length ? Object.fromEntries(map) : undefined

  if (params?.token && params?.code) {
    log('%cMagic Link token found. Session starts.', 'color: red')
    // This is not really necessary. The values should be set later
    checkoutQueue.init_login = 'ongoing'
    checkoutQueue.login_session = 'ongoing'
    checkoutQueue.magic_link_login = 'ongoing'
    client.login({ token: params.token, code: params.code }).then(async (res) => {
      if (res) {
        const session = res.session
        // If session found, keep the state of magic_link_login ongoing for the session cart update that comes after user cart loaded
        // Otherwise, end the session
        if (session.cart) {
          // Session found, load the cart from it, and re-init the cart
          log('%cUnfinished order in session found:', 'color: red', session)
          localStorage?.setItem(reactiveCartKey, JSON.stringify(session.cart))
          await reactiveCart.initCart()
        } else {
          log('%cNo unfinished order. Session ends.', 'color: red')
          checkoutQueue.magic_link_login = 'done'
          checkoutQueue.login_session = 'inactive'
        }
      } else checkoutQueue.login_session = 'inactive'
      checkoutQueue.init_login = 'done'
    }).catch(async (e: Error) => {
      checkoutQueue.init_login = 'done'
      checkoutQueue.login_session = 'inactive'
      if (e.status === 401) {
        checkoutQueue.magic_link_login = 'waiting'
        if (instance) await handleMagicLinkError()
      }
    })
  } else await initAndRefresh()
}

async function handleMagicLinkError(): Promise<void> {
  log('%cInvalid token. Session ends.', 'color: red')
  await instance.$createModal(alertModal as any, {
    title: instance.$t('checkout.error-general-headline'),
    description: instance.$t('authentication.invalid-magic-link'),
    url: instance.$route.path,
    locked: true,
  })
  checkoutQueue.magic_link_login = 'inactive'
}

async function initAndRefresh(): Promise<void | string> {
  const lsAccessToken = localStorage.getItem(accessTokenKey)
  const lsRefreshToken = localStorage.getItem(refreshTokenKey)

  if (!lsRefreshToken) return checkoutQueue.init_login = 'done'
  else if (lsRefreshToken && lsAccessToken) {
    // Set token before trying to get the user
    client.accessToken = lsAccessToken
    client.refreshToken = lsRefreshToken

    try {
      const user = await client.getUser()
      userRef.value = user
      accessTokenRef.value = lsAccessToken
      refreshTokenRef.value = lsRefreshToken
    } catch (error) {
      logError(error)
    } finally {
      checkoutQueue.init_login = 'done'
    }
  } else if (lsRefreshToken && !lsAccessToken) {
    const result = await client.login({ refresh_token: lsRefreshToken })
    if (!result) localStorage.removeItem(refreshTokenKey)
    checkoutQueue.init_login = 'done'
  }
  analyticsEvent('initial_login', { result: user ? 'success' : refreshToken ? 'invalid_token' : 'no_token' })
}

/* ========================================================================== *
 * Initialize app: perform initial login and router initialization then mount *
 * ========================================================================== */
initCohort()
initializeAnalytics()
initializeDishRatings()

// Referral code initialization
const referral_code = new URLSearchParams(window.location.search).get('referral_code')?.toUpperCase()
if (referral_code) localStorage?.setItem('referral_code', referral_code)

// Compado "Click ID" initialization
const clickid = new URLSearchParams(globalThis.window.location.search).get('clickid')
if (clickid) {
  // Save the click ID with a timestamp, we'll need this for 15 days...
  const data: CompadoData = { clickid, timestamp: Date.now() }
  localStorage.setItem('jn:compado', JSON.stringify(data))
}

// Create our app first
const app = createJuitApp()
const root = document.querySelector('#app') || document.querySelector('#root') as Element

// Initialize Sentry Asynchronously
async function initSentry(): Promise<void> {
  if (window.location.hostname === 'localhost') return
  if (window.location.hostname === '127.0.0.1') return
  const { setupSentry } = await import('./init/sentry')
  setupSentry(app)
}

// Initialize Stripe
export const stripe = await loadStripe(env.VITE_STRIPE_API_KEY)

// Check for ApplePay availability
export const applepay_support = await applePayCapabilitiesCheck()
log('%cApplePay available:', 'font-weight:bold; color: teal', applepay_support)

// Add window as global property
app.config.globalProperties.window = window

export let instance: ComponentPublicInstance

// Mount and initialize the app
async function mountApp(): Promise<void> {
  log('Mounting the app')
  instance = app.mount(root)

  // Don't do anything on maintenance page
  if (maintenance_on) return

  // Check for stored privacy tier
  if (! privacyTier.value) {
    instance.$createModal(cookiesModal as any).catch((e) => logError(e))
  }

  // Failsafe for init_login state
  if (checkoutQueue.init_login !== 'done') checkoutQueue.init_login = 'done'

  // AFTER the initial login, we start watching for user changes and setting
  // the appropriate locale. This avoids double fetching of translations when
  // a user goes to `/de` but his profile is set to `en` (for example)
  watch(user, (user) => user && void setLocale(user.locale))

  // If the failed magic link login attempt is still not handled, do it now
  if (checkoutQueue.magic_link_login === 'waiting') await handleMagicLinkError()
}
// When router is ready
router.isReady().then(async () => {
  log('Router ready. Initializing cohort and user...')

  // Do the initial login and mount the app regardless
  try {
    // Don't do anything on maintenance page
    if (maintenance_on) return
    else await Promise.all([ initialLogin(), initSentry() ])
  } catch (error) {
    logError(error)
  } finally {
    mountApp()
  }
}).catch((error) => logError(error))
